import classNames from "classnames";
import React from "react";
import styles from "./index.module.scss";

interface IProps {
  id?: string;
  mode?: "black" | "white";
  title: string;
  words: string[];
}

const SectionTitle: React.FC<IProps> = (props) => {
  const { id, mode = "black", title, words } = props;
  return (
    <div
      id={id}
      className={classNames(styles.container, mode === "black" ? styles.black : styles.white)}
    >
      <div className={classNames(styles.content, "flex-row flex-cross-axis-center")}>
        <div className={styles.title}>{title}</div>
        <div className={styles.words}>{words.join(" / ")}</div>
      </div>
    </div>
  );
};

export default SectionTitle;
