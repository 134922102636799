import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import SectionTitle from "../../../../common/components/section-title";
import styles from "./index.module.scss";
import AppRouter from "@/common/app/router";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";

const oss = getOssAssetsGenerator("home-page/agent/");

interface IQuestionItem {
  titleImg: string;
  icon: string;
  name: string;
  desc: string[];
}

const HomeSectionAgent: React.FC = (props) => {

  // -------------------- Events --------------------
  const jump = useCallback(() => {
    window.open(AppRouter.Agent.business)
  }, []);

  // -------------------- UI --------------------

  // 左侧
  const leftUI = (
    <div className={styles.left} style={{ backgroundImage: `url(${oss("left-bg.jpeg")})` }}>
      <div className={styles.leftTitle}>一站式电商运营推广</div>
      <div className={styles.sub}>打造属于自己的品牌影响力</div>
      <div className={styles.line} />
      <div style={{ height: "100%" }}>
        <div className={styles.item}>
          <div className={styles.itemNum}>5+</div>
          <div className={styles.itemName}>官方认证</div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemNum}>10000+</div>
          <div className={styles.itemName}>服务客户</div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemNum}>200+</div>
          <div className={styles.itemName}>运营成员</div>
        </div>
      </div>
    </div>
  );

  // 右侧 - 单个问题栏
  const createQuestionCard = useCallback((item: IQuestionItem, index: number) => {
    return (
      <div key={index} className={classNames(styles.questionItem, "flex-col")} onClick={jump}>
        <img src={item.titleImg} alt="" />
        <div className={classNames(styles.questionContent, "flex-row flex-cross-axis-center")}>
          <div className={classNames(styles.questionLeft, "flex-col-center")}>
            <div className={styles.questionIcon}>
              <img src={item.icon} alt="" />
            </div>
            <div className={styles.questionName}>{item.name}</div>
          </div>
          <div className={styles.questionLine} />
          <div className="flex-col flex-main-axis-center">
            {item.desc.map((desc, index) => (
              <div key={index} className={styles.questionDesc}>
                {desc}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }, []);

  // 右侧
  const rightUI = useMemo(() => {
    const questions: IQuestionItem[] = [
      {
        titleImg: oss("q-title1.jpeg"),
        icon: oss("q-icon1.png"),
        name: "无团队",
        desc: ["找不到合适的运营？", "没有专业主播？", "没有专属客服团队？"],
      },
      {
        titleImg: oss("q-title2.jpeg"),
        icon: oss("q-icon2.png"),
        name: "无方向",
        desc: ["产品定位不清晰？", "不熟悉平台运营规则？", "不会品牌包装？", "不会视觉打造？"],
      },
      {
        titleImg: oss("q-title3.jpeg"),
        icon: oss("q-icon3.png"),
        name: "无资源",
        desc: ["传统线下转线上？", "没有各大电商平台渠道？", "没有运营技术沉淀？"],
      },
    ];

    return (
      <div className={styles.right}>
        <div className="flex-row flex-cross-axis-center">
          <div className={styles.rightTitle}>网店不会运营？汇蚁网为您提供专业的网店代运营服务解决方案</div>
          <img className={styles.titleIcon} src={oss("arrow-right.png")} alt="" onClick={jump} />
        </div>
        {/* 优势 */}
        <div
          className={classNames(
            styles.advs,
            "flex-row flex-main-axis-between flex-cross-axis-center"
          )}
        >
          <div className={classNames(styles.advItem, "flex-row flex-cross-axis-center")}>
            <img src={oss("adv1.png")} alt="" />
            <div>
              <div className={styles.text1}>8年经验</div>
              <div className={styles.text2}>把握最新行业动态</div>
            </div>
          </div>
          <div className={styles.seperator} />
          <div className={classNames(styles.advItem, "flex-row flex-cross-axis-center")}>
            <img src={oss("adv2.png")} alt="" />
            <div>
              <div className={styles.text1}>资深团队</div>
              <div className={styles.text2}>人均5年以上经验，专业可靠</div>
            </div>
          </div>
          <div className={styles.seperator} />
          <div className={classNames(styles.advItem, "flex-row flex-cross-axis-center")}>
            <img src={oss("adv3.png")} alt="" />
            <div>
              <div className={styles.text1}>定制化服务</div>
              <div className={styles.text2}>针对定制代运营方案</div>
            </div>
          </div>
        </div>
        {/* 问题 */}
        <div className={classNames(styles.questions, "flex-row flex-main-axis-between")}>
          {questions.map((item, index) => createQuestionCard(item, index))}
        </div>
      </div>
    );
  }, [createQuestionCard]);

  return (
    <div className={styles.section}>
      <SectionTitle
        title="代运营服务"
        words={["8年电商运营经验", "一对一定制店铺运营计划", "全平台官方运营合作服务商"]}
      />
      <div className={classNames(styles.content, "flex-row")}>
        {leftUI}
        {rightUI}
      </div>
    </div>
  );
};

export default HomeSectionAgent;
