import React, { CSSProperties, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import SectionTitle from "../../../../common/components/section-title";
import classNames from "classnames";
import KST from "@/common/tools/kst";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";

const oss = getOssAssetsGenerator("home-page/settled/");

interface IPlatform {
  platformIcon: string;
  words: string[];
}

const config: IPlatform[] = [
  { platformIcon: oss("jd.png"), words: ["京东慧采", "京东自营", "京东Pop", "京东小店"] },
  {
    platformIcon: oss("tmall.png"),
    words: ["天猫旗舰店", "天猫专营店", "天猫专卖店", "天猫国际店"],
  },
  { platformIcon: oss("pdd.png"), words: ["多多旗舰店", "多多专营店", "多多专卖店", "多多个体店"] },
  {
    platformIcon: oss("douyin.png"),
    words: ["抖音旗舰店", "抖音专营店", "抖音专卖店", "抖音个体店"],
  },
];

const HomeSectionSettled: React.FC = (props) => {
  const createPlatform = useCallback((item: IPlatform, style?: CSSProperties) => {
    return (
      <div
        style={style}
        className={classNames(styles.platform, "flex-row")}
        onClick={() => KST.call()}
      >
        <img src={item.platformIcon} alt="" />
        <div
          className={classNames(
            styles.types,
            "flex-col flex-main-axis-center flex-cross-axis-center"
          )}
        >
          {item.words.map((text, index) => (
            <div key={index} className={classNames(styles.item, "flex-row flex-cross-axis-center")}>
              <div className={styles.dot} />
              <div className={styles.text}>{text}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }, []);

  const platformUI = useMemo(() => {
    return (
      <div className="flex-col">
        <div className="flex-row">
          {createPlatform(config[0], { marginRight: 16 })}
          {createPlatform(config[1])}
        </div>
        <div style={{ marginTop: 16 }} className="flex-row">
          {createPlatform(config[2], { marginRight: 16 })}
          {createPlatform(config[3])}
        </div>
      </div>
    );
  }, [createPlatform]);

  const rightUI = (
    <div className={styles.right}>
      <img className={styles.logo} src={oss("logo.png")} alt="" />
      <div className={styles.desc}>
        汇蚁网是京东、天猫官方合作伙伴。专营京东自营店铺、京东慧采店铺的入驻服务与天猫店和各种网店代运营服务，一对一专属方案绿色通道全类目入驻，1-2周走完所有流程，下店后收费
      </div>
      <div style={{ marginTop: 60 }}>
        <div
          className={classNames(
            styles.option,
            "flex-row flex-main-axis-between flex-cross-axis-center"
          )}
        >
          <div className={classNames(styles.optionContent, "flex-row-center")}>
            <img className={styles.icon} src={oss("check.png")} alt="" />
            <div className={styles.notice}>免费获取入驻方案</div>
          </div>
          <div className={classNames(styles.button, "flex-row-center")} onClick={() => KST.call()}>
            立即获取
          </div>
        </div>
        <div
          style={{ marginTop: 22 }}
          className={classNames(
            styles.option,
            "flex-row flex-main-axis-between flex-cross-axis-center"
          )}
        >
          <div className={classNames(styles.optionContent, "flex-row-center")}>
            <img className={styles.icon} src={oss("check.png")} alt="" />
            <div className={styles.notice}>专业顾问在线解答您的疑惑</div>
          </div>
          <div className={classNames(styles.button, "flex-row-center")} onClick={() => KST.call()}>
            在线咨询
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.section}>
      <div className={styles.bg}>
        <img src={oss("bg-new.jpeg")} alt="" />
        <div className={styles.content}>
          <SectionTitle
            mode="white"
            title="电商入驻"
            words={["京东官方认证服务商", "天猫官方合作伙伴", "八年资深团队"]}
          />
          <div style={{ marginTop: 65 }} className="flex-row">
            {platformUI}
            {rightUI}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionSettled;
