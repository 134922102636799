import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";

import SectionTitle from "../../../../common/components/section-title";
import classNames from "classnames";
import ModuleTM from "@/modules/tm";
import TMModel from "@/modules/tm/api/tm/tm-model";
import KST from "@/common/tools/kst";
import AppRouter from "@/common/app/router";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";

const oss = getOssAssetsGenerator("home-page/tm-transfer/");

const HomeSectionTMTransfer: React.FC = (props) => {
  const [index, setIndex] = useState<0 | 1>(0);
  const [normalData, setNormalData] = useState<TMModel[]>();
  const [qualityData, setQualityData] = useState<TMModel[]>();

  // -------------------- Effect & Request --------------------

  // 请求商标
  const request = useCallback(async (type: 0 | 1) => {
    const response = await ModuleTM.Api.homeList(type);
    if (!response.success) {
      return;
    }

    if (response.model!.list.length > 8) {
      response.model!.list = response.model!.list.slice(0, 8);
    }
    if (type === 0) setNormalData(response.model!.list);
    else setQualityData(response.model!.list);
  }, []);

  // 初始化
  useEffect(() => {
    const data = index === 0 ? normalData : qualityData;
    if (data === undefined) {
      request(index);
    }
  }, [request, index, normalData, qualityData]);

  // -------------------- Events --------------------

  const callKST = useCallback(() => {
    KST.call();
  }, []);

  // -------------------- UI --------------------

  // 左侧菜单
  const leftMenuUI = useMemo(() => {
    return (
      <div className={classNames(styles.left, "flex-shrink0 flex-col")}>
        <div
          className={classNames(styles.header, "flex-shrink0")}
          style={{ backgroundImage: `url(${oss("left-header.jpg")})` }}
        >
          <div className={styles.headerTitle}>海量商标</div>
          <div className={styles.headerDesc}>
            150w+<span>任意选</span>
          </div>
        </div>
        <div className="flex-grow1 flex-col">
          <div
            className={classNames(styles.menu, index === 0 ? styles.select : styles.normal)}
            onClick={() => setIndex(0)}
          >
            <img src={oss("tm-normal.png")} alt="" />
            <div>千元商标</div>
            {index === 0 && <div className={styles.selectBar} />}
            <div className={styles.more} onClick={() => window.open(AppRouter.TM.transfer())}>
              查看更多
            </div>
          </div>
          <div
            className={classNames(styles.menu, index === 1 ? styles.select : styles.normal)}
            onClick={() => setIndex(1)}
          >
            <img src={oss("tm-quality.png")} alt="" />
            <div>精品商标</div>
            {index === 1 && <div className={styles.selectBar} />}
            <div className={styles.more} onClick={() => window.open(AppRouter.TM.transfer())}>
              查看更多
            </div>
          </div>
        </div>
      </div>
    );
  }, [index]);

  // 右侧商标
  const rightTMUI = useMemo(() => {
    const data = (index === 0 ? normalData : qualityData) ?? [];
    return (
      <div className={classNames(styles.right, "flex-grow1")}>
        {data.map((item) => {
          return (
            <div
              key={item.code}
              className={classNames(styles.item, "flex-col flex-cross-axis-center")}
              onClick={() => window.open(AppRouter.TM.detail(item.code))}
            >
              <img src={item.renderPhoto} alt="" />
              <div className={styles.name}>{item.uiName}</div>
              <div className={styles.contact} onClick={callKST}>
                立即询价
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [index, normalData, qualityData]);

  return (
    <div className={styles.section}>
      <div className={styles.bg}>
        <img src={oss("bg.jpeg")} alt="" />
        <div className={styles.content}>
          <SectionTitle
            title="商标转让"
            words={["国家认证机构", "专业一对一服务", "知产精英团队"]}
          />
          <div className={classNames(styles.container, "flex-row")}>
            {leftMenuUI}
            {rightTMUI}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionTMTransfer;
