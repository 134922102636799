import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SectionTitle from "../../../../common/components/section-title";
import styles from "./index.module.scss";

import ModuleCMS from "@/modules/cms";
import CMSArticleHomeListModel from "@/modules/cms/api/models/article-home-list-model";
import AppRouter from "@/common/app/router";
import HYLink from "@/common/components/link/link";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";

const oss = getOssAssetsGenerator("home-page/cms/");

const HomeSectionCMS: React.FC = (props) => {
  const [select, setSelect] = useState(0);
  const [data, setData] = useState<CMSArticleHomeListModel[]>([]);

  // ------------------------- Effect & Request -------------------------

  // 请求文章
  const request = useCallback(async () => {
    const response = await ModuleCMS.Api.Article.homeList({
      // 1-知识产权 2-入驻 3-品牌授权 4-代运营
      classify_id: [2, 4, 1],
      page: 1,
      page_size: 4,
    });
    if (!response.success) {
      return;
    }

    const model = response.model!;
    const items: CMSArticleHomeListModel[] = [];
    let target = model.list.find((i) => i.classifyId === 2);
    if (target) items.push(target);
    target = model.list.find(i => i.classifyId === 4);
    if (target) items.push(target);
    target = model.list.find(i => i.classifyId === 1);
    if (target) items.push(target);
    setData(items);

  }, []);

  useEffect(() => {
    request();
  }, [request])

  // ------------------------- Events -------------------------

  // ------------------------- UI -------------------------

  // 顶部菜单
  const menuUI = useMemo(() => {
    const createMenu = (icon: string, title: string, desc: string, index: number) => {
      return (
        <div
          className={classNames(
            styles.menu,
            index === select ? styles.menuSelect : styles.menuNormal,
            "flex-row flex-cross-axis-center"
          )}
          style={index === select ? { backgroundImage: `url(${oss("menu-select-bg.png")})` } : undefined}
          onClick={() => setSelect(index)}
        >
          <img src={icon} alt="" />
          <div>
            <div className={styles.menuTitle}>{title}</div>
            <div className={styles.menuDesc}>{desc}</div>
          </div>
        </div>
      );
    };

    return (
      <div className={classNames(styles.topMenus, "flex-row")}>
        {createMenu(oss("settled.png"), data[0]?.classifyName ?? "", data[0]?.classifyDesc ?? "", 0)}
        {createMenu(oss("agent.png"), data[1]?.classifyName ?? "", data[1]?.classifyDesc ?? "", 1)}
        {createMenu(oss("intellectual.png"), data[2]?.classifyName ?? "", data[2]?.classifyDesc ?? "", 2)}
      </div>
    );
  }, [data, select]);

  // 文章列表
  const contentUI = useMemo(() => {
    const leftImgs = [oss("settled-left.jpg"), oss("agent-left.jpg"), oss("intellectual-left.jpg")];

    const createArticle = (key: number, title: string, desc: string, link: string) => {
      return (
        <HYLink key={key} href={link} className={classNames(styles.article, "flex-col flex-main-axis-center")}>
          <div className={styles.articleTitle}>{title}</div>
          <div className={styles.articleDesc}>{desc}</div>
        </HYLink>
      );
    };

    let classify = data[select];
    let list = classify?.list ?? [];
    if (list.length > 4) list = list.slice(0, 4);

    return (
      <div className="flex-row">
        <img className={styles.leftBg} src={leftImgs[select]} alt="" />
        <div style={{ overflow: "hidden" }} className="flex-col flex-main-axis-between">
          <div>
            {list.map(i => createArticle(i.id, i.title, i.brief, AppRouter.CMS.detail(i.id)))}
          </div>
          <div className={styles.button} onClick={() => window.open(AppRouter.CMS.list({page: 1, classify: classify.classifyId}))}>{"查看更多 >"}</div>
        </div>
      </div>
    );
  }, [select, data]);

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <SectionTitle
          title="咨询中心"
          words={["掌握一手京东、天猫等多个电商平台入驻资讯及入驻条件和流程。汇蚁网专业团队 / 规避商业陷阱 / 蚁路同行。"]}
        />
        <div className={styles.container}>
          {menuUI}
          {contentUI}
        </div>
      </div>
    </div>
  );
};

export default HomeSectionCMS;
