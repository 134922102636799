import { Carousel } from "antd";
import React, { useMemo } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import AppRouter from "@/common/app/router";
import KST from "@/common/tools/kst";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";
import Image from "next/image";

const oss = getOssAssetsGenerator("home-page/header/");

interface IBusienssItem {
  icon: string;
  name: string;
  link?: string;
  onTap?: () => void;
}

interface IBusiness {
  titleBg: string;
  title: string;
  titleEng: string;
  items: IBusienssItem[];
}

interface IButtom {
  icon: string;
  text1: string;
  text2: string;
}

// Banner图配置
const bannerConfig = [
  {
    img: oss("banner-jd.jpeg"),
    title: "京东认证服务商",
    desc: ["8年资深团队", "慧采自营绿色通道", "最快当天下店"],
    link: AppRouter.Settled.business("jdhc"),
  },
  {
    img: oss("banner-tmall.jpeg"),
    title: "天猫官方合作伙伴",
    desc: ["官方认证", "安全可靠", "全类目快至1周下店"],
    link: AppRouter.Settled.business("tmall"),
  },
  {
    img: oss("banner-agent.jpeg"),
    title: "电商运营一站式服务",
    desc: ["一对一定制运营计划", "全平台运营服务商", "打造属于您自己的品牌"],
    link: AppRouter.Agent.business,
  },
];

// 功能区配置
const panelFunctionConfigs: IBusiness[] = [
  {
    title: "电商入驻",
    titleEng: "Entry of e-commerce",
    titleBg: oss("panel/business-settled/title-bg.jpeg"),
    items: [
      {
        icon: oss("panel/business-settled/jdhc.png"),
        name: "京东慧采",
        link: AppRouter.Settled.business("jdhc"),
      },
      {
        icon: oss("panel/business-settled/jdzy.png"),
        name: "京东自营",
        link: AppRouter.Settled.business("jdzy"),
      },
      {
        icon: oss("panel/business-settled/jdpop.png"),
        name: "京东POP",
        link: AppRouter.Settled.business("jdpop"),
      },
      {
        icon: oss("panel/business-settled/tmall.png"),
        name: "天猫入驻",
        link: AppRouter.Settled.business("tmall"),
      },
      {
        icon: oss("panel/business-settled/douyin.png"),
        name: "抖音入驻",
        link: AppRouter.Settled.business("douyin"),
      },
      {
        icon: oss("panel/business-settled/other.png"),
        name: "其他入驻",
        onTap: () => KST.call(),
      },
    ],
  },
  {
    title: "代运营服务",
    titleEng: "Agent operation service",
    titleBg: oss("panel/business-agent/title-bg.jpeg"),
    items: [
      {
        icon: oss("panel/business-agent/zddyy.png"),
        name: "整店代运营",
        link: AppRouter.Agent.business,
      },
      {
        icon: oss("panel/business-agent/pptg.png"),
        name: "品牌推广",
        link: AppRouter.Agent.business,
      },
      {
        icon: oss("panel/business-agent/dpzx.png"),
        name: "店铺装修",
        link: AppRouter.Agent.business,
      },
      {
        icon: oss("panel/business-agent/zbdh.png"),
        name: "直播带货",
        link: AppRouter.Agent.business,
      },
      {
        icon: oss("panel/business-agent/sjsj.png"),
        name: "视觉设计",
        link: AppRouter.Agent.business,
      },
      {
        icon: oss("panel/business-agent/dspps.png"),
        name: "短视频拍摄",
        link: AppRouter.Agent.business,
      },
    ],
  },
  {
    title: "知识产权服务",
    titleEng: "Intellectual Property Services",
    titleBg: oss("panel/business-intellectual/title-bg.jpeg"),
    items: [
      {
        icon: oss("panel/business-intellectual/tm-transfer.png"),
        name: "商标转让",
        link: AppRouter.TM.transfer(),
      },
      {
        icon: oss("panel/business-intellectual/tm-register.png"),
        name: "商标注册",
        link: AppRouter.TM.business,
      },
      {
        icon: oss("panel/business-intellectual/tm-international.png"),
        name: "国际商标",
        link: AppRouter.TM.international,
      },
      {
        icon: oss("panel/business-intellectual/tm-case.png"),
        name: "商标案件",
        onTap: () => KST.call(),
      },
      {
        icon: oss("panel/business-intellectual/patent.png"),
        name: "专利服务",
        link: AppRouter.Intellectual.patent,
      },
      {
        icon: oss("panel/business-intellectual/copyrights.png"),
        name: "软著服务",
        link: AppRouter.Intellectual.copyrights,
      },
    ],
  },
];

// 功能区底部配置
const panelBottomConfigs: IButtom[] = [
  {
    icon: oss("panel/bottom/1.png"),
    text1: "官方服务商",
    text2: "京东、天猫官方认证，安全可靠",
  },
  {
    icon: oss("panel/bottom/2.png"),
    text1: "国家认证机构",
    text2: "官方商标局权威认证，服务专业有保障",
  },
  {
    icon: oss("panel/bottom/3.png"),
    text1: "资深团队",
    text2: "8年资深电商经验，专业法务团队护航",
  },
  {
    icon: oss("panel/bottom/4.png"),
    text1: "专属顾问",
    text2: "对一服务，定制专属方案",
  },
];

const HomeHeader: React.FC = (props) => {

  // Banner
  const bannerUI = useMemo(() => {
    return (
      <Carousel
        className={styles.carousel}
        dotPosition="bottom"
        autoplay
        autoplaySpeed={5000}
        pauseOnHover={false}
        effect="fade"
      >
        {bannerConfig.map((item, index) => {
          return (
            <div key={index} className={styles.banner} onClick={() => window.open(item.link)}>
              <img src={item.img} />
              <div className={styles.content}>
                <div className={styles.title}>{item.title}</div>
                <div className={classNames(styles.descList, "flex-row")}>
                  {item.desc.map((desc, index) => {
                    return (
                      <div key={index} className={styles.desc}>
                        {desc}
                      </div>
                    );
                  })}
                </div>
                <div
                  className={styles.button}
                  onClick={(e) => {
                    e.stopPropagation();
                    KST.call();
                  }}
                >
                  立即咨询<span>→</span>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    );
  }, []);

  // 功能面板
  const panelUI = useMemo(() => {
    // 单个功能区
    const createBusienss = (config: IBusiness) => {
      return (
        <div className={styles.business}>
          <div
            className={classNames(styles.titleBg, "flex-col flex-main-axis-center")}
            style={{ backgroundImage: `url(${config.titleBg})` }}
          >
            <div className={styles.title}>{config.title}</div>
            <div className={styles.titleEng}>{config.titleEng}</div>
          </div>
          <div
            className={classNames(
              styles.businessItems,
              "flex-row-wrap flex-main-axis-between flex-cross-axis-center"
            )}
          >
            {config.items.map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames(styles.item, "flex-col-center")}
                  onClick={() => {
                    if (item.link) window.open(item.link);
                    else if (item.onTap) item.onTap();
                  }}
                >
                  <img src={item.icon} alt="" />
                  <div className={styles.name}>{item.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    // 底部特色item
    const createBottomItem = (config: IButtom) => {
      return (
        <div className="flex-row flex-cross-axis-center">
          <img src={config.icon} alt="" />
          <div>
            <div className={styles.text1}>{config.text1}</div>
            <div className={styles.text2}>{config.text2}</div>
          </div>
        </div>
      );
    };

    return (
      <div className={classNames(styles.panel, "flex-col flex-main-axis-between")}>
        <div className={classNames(styles.function, "flex-row flex-main-axis-between")}>
          {createBusienss(panelFunctionConfigs[0])}
          <div className={styles.line} />
          {createBusienss(panelFunctionConfigs[1])}
          <div className={styles.line} />
          {createBusienss(panelFunctionConfigs[2])}
        </div>
        <div
          className={classNames(
            styles.bottom,
            "flex-row flex-main-axis-between flex-cross-axis-center"
          )}
        >
          {createBottomItem(panelBottomConfigs[0])}
          <div className={styles.line} />
          {createBottomItem(panelBottomConfigs[1])}
          <div className={styles.line} />
          {createBottomItem(panelBottomConfigs[2])}
          <div className={styles.line} />
          {createBottomItem(panelBottomConfigs[3])}
        </div>
      </div>
    );
  }, []);

  return (
    <div className={styles.header}>
      {bannerUI}
      {panelUI}
    </div>
  );
};

export default HomeHeader;
