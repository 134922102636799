import HYHead from "@/common/components/head";
import PageFooter from "@/common/components/page-footer";
import PageHeader from "@/common/components/page-header";
import HomeSectionAgent from "@/modules/home/components/agent";
import HomeSectionCMS from "@/modules/home/components/cms";
import HomeSectionCooperation from "@/modules/home/components/cooperation";
import HomeHeader from "@/modules/home/components/header";
import HomeSectionIntellectual from "@/modules/home/components/intellectual";
import HomeSectionSettled from "@/modules/home/components/settled";
import HomeSectionTeam from "@/modules/home/components/team";
import HomeSectionTMTransfer from "@/modules/home/components/tm-transfer";
import styles from "./index.module.scss";
import HomeSectionAboutUs from "@/modules/home/components/about-us";
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from "next";
import { withHYSSR } from "@/common/components/ssr/with-hy-ssr";
import _ from "lodash";
import ModuleOthers from "@/modules/others";
import { useMemo } from "react";
import { ListModel } from "@/common/defines/list-model";
import { WebLinkModel } from "@/modules/others/api/models/web-link-model";

// SSR
export const getServerSideProps: GetServerSideProps = withHYSSR(async (context) => {
  // 请求友情链接
  const response = await ModuleOthers.Api.webLink();
  if (!response.success) {
    return { notFound: true };
  }
  return {
    props: {
      weblinkData: response.data.data,
    },
  };
});

const Page: NextPage = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { weblinkData } = props;
  const webLinkModel = useMemo(() => {
    return new ListModel(weblinkData, WebLinkModel, true);
  }, [weblinkData]);

  const headerUI = (
    <div className={styles.headerSection}>
      <PageHeader mode="white" className={styles.navi} />
    </div>
  );

  return (
    <div className={styles.page}>
      <HYHead
        description="【天猫京东双认证五星服务商】汇蚁网-专注于京东自营入驻、京东慧采入驻、天猫入驻、抖音入驻，内部渠道邀约，专人快审。网店托管、整店运营、客服外包、网店视觉设计，按效果付费，签署合同，省时省心！"
      />
      {headerUI}
      <HomeHeader />
      {/* <HomeSectionAboutUs /> */}
      <HomeSectionSettled />
      <HomeSectionAgent />
      <HomeSectionTMTransfer />
      <HomeSectionIntellectual />
      <HomeSectionCooperation />
      <HomeSectionTeam />
      <HomeSectionCMS />
      <PageFooter webLinkData={webLinkModel} />
    </div>
  );
};

export default Page;
