import React from "react";
import Head from "next/head";

export interface IHYHeadProps {
  title?: string;
  disableSuffix?: boolean;
  description?: string;
  keywords?: string;
}

const HYHead: React.FC<IHYHeadProps> = (props) => {
  const {
    title = "天猫-京东-抖音-全电商平台入驻-网店代运营",
    disableSuffix = false,
    description = "汇蚁网专业的电商服务平台专营京东自营、京东慧采店铺代入驻服务,以及京东自营,慧采,天猫店,淘宝店,抖小店,拼多多等众多电商平台的网店代运营及商标买卖服务,欢迎咨询",
    keywords = "天猫入驻,京东入驻,慧采入驻,拼多多入驻,抖小店入驻,店铺代运营,商标转让,商标注册",
  } = props;
  const titleSuffix = disableSuffix ? "" : "-汇蚁网";

  return (
    <Head>
      <title>{title + titleSuffix}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Head>
  );
};

export default HYHead;
