import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import SectionTitle from "../../../../common/components/section-title";
import styles from "./index.module.scss";
import KST from "@/common/tools/kst";
import { Carousel } from "antd";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";
import OthersApi from "@/modules/others/api/api";
import { TeamPersonModel } from "@/modules/others/api/models/team-person-model";
import { ListModel } from "@/common/defines/list-model";
import { nanoid } from "nanoid";

const oss = getOssAssetsGenerator("home-page/team/");

interface IPerson {
  img: string;
  name: string;
  job: string;
}

const HomeSectionTeam: React.FC = (props) => {
  const [person, setPerson] = useState<TeamPersonModel[]>();
  const personDatasource = useMemo(() => {
    function chunkArray<T>(arr: T[], size: number): T[][] {
      const result: T[][] = [];
      const numChunks = Math.ceil(arr.length / size);
      for (let i = 0; i < numChunks; i++) {
        const start = i * size;
        const end = start + size;
        result.push(arr.slice(start, end));
      }
      return result;
    }
    return chunkArray(person ?? [], 10);
  }, [person]);

  // ------------- Request & Effect -------------

  // 获取团队数据
  const personRequest = useCallback(async () => {
    const response = await OthersApi.teamPerson((cacheResponse) => {
      try {
        if (cacheResponse.success) {
          const model: ListModel<TeamPersonModel> = cacheResponse.model as any;
          setPerson(model.list);
        }
      } catch (e) {
        console.log("Exception:", e);
      }
    });
    if (!response.success) {
      return;
    }
    setPerson(response.model!.list);
  }, []);

  // 初始化
  useEffect(() => {
    personRequest();
  }, [personRequest]);

  // ------------- Events -------------

  // 点击快商通
  const callKST = useCallback(() => {
    KST.call();
  }, []);

  // ------------- UI -------------

  // 单个员工
  const createItem = useCallback((icon: string, title: string, desc: string) => {
    return (
      <div className={classNames(styles.item, "flex-row")}>
        <img className={styles.dot} src={oss("dot.png")} alt="" />
        <div>
          <img className={styles.icon} src={icon} alt="" />
          <div className={styles.itemTitle}>{title}</div>
          <div className={styles.itemDesc}>{desc}</div>
        </div>
      </div>
    );
  }, []);

  // 左侧宣传文案
  const leftUI = (
    <div
      className={classNames(styles.left, "flex-shrink0")}
      style={{ backgroundImage: `url(${oss("left-bg.jpg")})` }}
    >
      <div className={styles.title}>汇聚梦想 蚁齐同行</div>
      <div className={styles.titleDesc}>{/**Gather dreams / walk together */}</div>
      {createItem(oss("icon1.png"), "专注于", "一站式企业服务")}
      {createItem(oss("icon2.png"), "致力于", "从企业服务痛点出发，提供对应的解决方案")}
      <div className={styles.button} onClick={callKST}>
        更多咨询 →
      </div>
    </div>
  );

  // 创建人物卡片
  const createPerson = useCallback((img: string, name: string, job: string) => {
    return (
      <div key={nanoid()} className={styles.person} style={{ backgroundImage: `url(${img})` }}>
        <div className={classNames(styles.bottom, "flex-col-center")}>
          <div className="flex-row flex-cross-axis-center">
            <div className={styles.personName}>{name}</div>
            <div className={styles.personJob}>{job}</div>
          </div>
          <div className={styles.contactButton} onClick={callKST}>
            {"立即咨询 >"}
          </div>
        </div>
      </div>
    );
  }, []);

  // 右侧人物区域
  const rightUI = (
    <Carousel
      className={classNames(styles.right, "flex-grow1")}
      autoplay
      pauseOnFocus={false}
      pauseOnHover={false}
      autoplaySpeed={5000}
    >
      {personDatasource.map((array, index) => (
        <div key={index} className={styles.gridContainer}>
          <div className={styles.grid}>
            {array.map((item) => {
              return createPerson(item.avatar, item.name, item.desc);
            })}
          </div>
        </div>
      ))}
    </Carousel>
  );

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <SectionTitle title="汇蚁团队" words={["八年京东自营慧采入驻、天猫店入驻及各类网店代运营服务经验。让我们蚁齐同行，携手并进"]} />
        <div className={classNames(styles.container, "flex-row")}>
          {leftUI}
          {rightUI}
        </div>
      </div>
    </div>
  );
};

export default HomeSectionTeam;
