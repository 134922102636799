import React from "react";
import styles from "./index.module.scss";
import SectionTitle from "../../../../common/components/section-title";
import Marquee from "react-fast-marquee";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";

const oss = getOssAssetsGenerator("home-page/cooperation/");

const iJD = oss("jd.png");
const iTmall = oss("tmall.png");
const iTaobao = oss("taobao.png");
const iDouyin = oss("douyin.png");
const iXhs = oss("xhs.jpg");
const iMgj = oss("mgj.jpg");


const iSuning = oss("suning.png");
const i360 = oss("360.png");
const iFuJianLuQiao = oss("fu-jian-lu-qiao.png");
const iHuawei = oss("huawei.png");
const iAigo = oss("aigo.jpg");
const iAdidas = oss("adidas.jpg");

const iNanJiRen = oss("nan-ji-ren.png");
const iMaoRen = oss("mao-ren.png");
const iNongFuShanQuan = oss("nong-fu-shan-quan.png");
const iSeptwolves = oss("sept-wolves.png");
const iYzl = oss("yzl.jpg");
const iNasa = oss("nasa.jpg");

const row1 = [
  iJD,
  iTmall,
  iTaobao,
  iDouyin,
  iXhs,
  iMgj,
  
];
const row2 = [
  iSuning,
  i360,
  iFuJianLuQiao,
  iHuawei,
  iAigo,
  iAdidas,
  
];
const row3 = [
  iNanJiRen,
  iMaoRen,
  iNongFuShanQuan,
  iSeptwolves,
  iYzl,
  iNasa,
];

const HomeSectionCooperation: React.FC = (props) => {
  return (
    <div className={styles.section}>
      <div className={styles.bg}>
        <img src={oss("bg-new.jpeg")} alt="" />
        <div className={styles.content}>
          <SectionTitle
            mode="white"
            title="合作伙伴"
            words={["多年行业沉淀", "深受业界认可", "赢得客户信赖"]}
          />
          <div className={styles.container}>
            <Marquee speed={30} gradient={false}>
              {row1.map((item, index) => (
                <img key={index} src={item} alt="" />
              ))}
            </Marquee>
            <Marquee style={{ marginTop: 44 }} direction="right" gradient={false}>
              {row2.map((item, index) => (
                <img key={index} src={item} alt="" />
              ))}
            </Marquee>
            <Marquee style={{ marginTop: 44 }} gradient={false}>
              {row3.map((item, index) => (
                <img key={index} src={item} alt="" />
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionCooperation;
