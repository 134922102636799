import classNames from "classnames";
import React, { useCallback } from "react";
import SectionTitle from "../../../../common/components/section-title";
import styles from "./index.module.scss";
import AppRouter from "@/common/app/router";
import KST from "@/common/tools/kst";
import { getOssAssetsGenerator } from "@/common/defines/ali-oss";

const oss = getOssAssetsGenerator("home-page/intellectual/");

interface IItem {
  icon: string;
  title: string;
  desc: string;
}

interface ICard {
  titleIcon: string;
  title: string;
  desc: string;
  item1: IItem;
  item2: IItem;
  link: string;
}

const config: ICard[] = [
  {
    titleIcon: oss("tm.png"),
    title: "商标服务",
    desc: "商标转让、商标注册、国际商标、信息变更\n异议服务，全套服务，应有尽有",
    item1: {
      icon: oss("tm-1.png"),
      title: "海量资源",
      desc: "150W商标任您选购",
    },
    item2: {
      icon: oss("tm-2.png"),
      title: "国家认证",
      desc: "商标局官方认证服务专业有保障",
    },
    link: AppRouter.TM.business,
  },
  {
    titleIcon: oss("patent.png"),
    title: "专利申请",
    desc: "抢占潜在专利市场，杜绝产品随意模仿\n用法律实时为您护航",
    item1: {
      icon: oss("patent-1.png"),
      title: "免费检索",
      desc: "提高申请成功率",
    },
    item2: {
      icon: oss("patent-2.png"),
      title: "专属顾问",
      desc: "精通行业法律，专业顾问时刻保障",
    },
    link: AppRouter.Intellectual.patent,
  },
  {
    titleIcon: oss("copyright.png"),
    title: "软著版权",
    desc: "预防原创文学被盗取，保护原创\n有效避免剽窃、盗窃行为",
    item1: {
      icon: oss("copyright-1.png"),
      title: "急速申报",
      desc: "快速递交版权局，10-15天拿证",
    },
    item2: {
      icon: oss("copyright-2.png"),
      title: "信息保密",
      desc: "客户资料严格保密，由专人管理",
    },
    link: AppRouter.Intellectual.copyrights,
  },
];

const HomeSectionIntellectual: React.FC = (props) => {
  // 创建单个卡片
  const createCard = useCallback((data: ICard) => {
    return (
      <div
        key={data.title}
        className={classNames(
          styles.card,
          "flex-col flex-main-axis-between flex-cross-axis-center flex-shrink0"
        )}
      >
        <div className="flex-col flex-cross-axis-center flex-shrink0">
          <img className={styles.titleIcon} src={data.titleIcon} alt="" />
          <div className={styles.title}>{data.title}</div>
          <div className={styles.desc}>{data.desc}</div>
        </div>
        <div style={{width: '100%'}} className="flex-col flex-cross-axis-center">
          <div className={classNames(styles.item, "flex-row flex-cross-axis-center")}>
            <img className={styles.itemIcon} src={data.item1.icon} alt="" />
            <div>
              <div className={styles.itemTitle}>{data.item1.title}</div>
              <div className={styles.itemDesc}>{data.item1.desc}</div>
            </div>
          </div>
          <div className={styles.itemLine} />
          <div className={classNames(styles.item, "flex-row flex-cross-axis-center")}>
            <img className={styles.itemIcon} src={data.item2.icon} alt="" />
            <div>
              <div className={styles.itemTitle}>{data.item2.title}</div>
              <div className={styles.itemDesc}>{data.item2.desc}</div>
            </div>
          </div>
          <div className={classNames(styles.buttons, "flex-row")}>
            <div className={classNames(styles.button, "flex-grow1 flex-row-center")} onClick={() => window.open(data.link)}>
              <img src={oss("search.png")} alt="" />
              <div className={styles.buttonText}>了解详情</div>
            </div>
            <div className={classNames(styles.button, "flex-grow1 flex-row-center")} onClick={() => KST.call()}>
              <img src={oss("chat.png")} alt="" />
              <div className={styles.buttonText}>立即咨询</div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <SectionTitle
          title="知识产权"
          words={["国家认证机构", "专业一对一服务", "知产精英团队"]}
        />
        <div className={classNames(styles.container, "flex-row flex-main-axis-between")}>
          {config.map((item) => createCard(item))}
        </div>
      </div>
    </div>
  );
};

export default HomeSectionIntellectual;
